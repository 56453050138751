<template>
  <section
    id="all-products"
    class="results-container"
    :class="{ 'results-container-extended': isMapExtended }"
  >
    <nav class="top-container">
      <div v-if="filtersCount === 0" class="filters-container-default">
        <div class="results-count-container">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-if="sectionTitle" class="count-title" v-html="sectionTitle" />

          <template v-else
            ><span v-if="results.length > 0" class="count-title">
              {{ totalCount }} {{ fullEntityName }}
            </span>
            <span v-else class="count-title">Aucun résultat</span>
          </template>
          <div v-if="showDropdown" class="dropdown-container">
            <FiltersDropdown
              v-if="currentCountryOption"
              v-model:value="currentCountryOption"
              class="dropdown"
              :options="countriesOptions"
              :placeholder="'Veuillez sélectionner un pays'"
            />
          </div>
        </div>
        <div v-if="isMapExtended" class="filters-buttons-container">
          <GroupDropdown
            v-if="resultType === 'journey'"
            v-model:value="currentOrdering"
            class="dropdown"
            :options="[
              { label: 'Trier par', value: 'default', type: 'default' },
              ...FILTERS_ORDERING
            ]"
            icon="sort-by"
            :is-text="false"
          />
          <button class="filter-button" @click="emit('toggle-modal', true)">
            <Icon name="filter-by" />
          </button>
        </div>
        <div v-else class="filters-buttons-container">
          <GroupDropdown
            v-if="resultType === 'journey'"
            v-model:value="currentOrdering"
            class="dropdown w-full"
            :options="[
              { label: 'Trier par', value: 'default', type: 'default' },
              ...FILTERS_ORDERING
            ]"
            icon="sort-by"
          />
          <button class="filter-button-outlined w-full" @click="emit('toggle-modal', true)">
            <Icon name="filter-by" />
            Filtrer par
          </button>
        </div>
      </div>
      <div v-else class="filters-container-filtered">
        <div class="results-count-container-filtered">
          <div class="title-container">
            <button class="back-button" @click="emit('on-reset')">Retour</button>
            <span v-if="totalCount && totalCount > 0 && !isLoading" class="main-title">
              {{ totalCount }} {{ fullEntityName }}
            </span>
          </div>
          <div v-if="!isMapExtended" class="buttons-filter">
            <GroupDropdown
              v-if="resultType === 'journey'"
              v-model:value="currentOrdering"
              class="dropdown"
              :options="[
                { label: 'Trier par', value: 'default', type: 'default' },
                ...FILTERS_ORDERING
              ]"
              icon="sort-by"
            />
            <button class="filter-button-outlined w-full" @click="emit('toggle-modal', true)">
              <Icon name="filter-by" />
              Filtrer par
              <CountBubble class="count-bubble" :count="filtersCount" />
            </button>
          </div>
          <div v-else class="buttons-filter">
            <button class="icon-filter-count filter-button" @click="emit('toggle-modal', true)">
              <Icon name="filter-by" />
              <CountBubble class="count-bubble" :count="filtersCount" />
            </button>
            <FiltersDropdown
              v-if="resultType === 'journey'"
              v-model:value="currentOrdering"
              class="dropdown"
              :options="[
                { label: 'Trier par', value: 'default', type: 'default' },
                ...FILTERS_ORDERING
              ]"
              icon="sort-by"
              :is-text="false"
            />
          </div>
        </div>
        <div v-if="!isFiltersExpanded" class="filters-tags-container">
          <div
            v-for="filter in filters"
            :key="filter.value"
            class="filter-component filter-tag"
            :class="{ disabled: filter.isDisabled }"
          >
            {{ filter.text }}
            <Icon
              v-if="!filter.isDisabled"
              name="small-close"
              class="small-close-icon"
              fill="#656464"
              @click="handleDeleteFilter(filter)"
            />
          </div>
          <button v-if="filtersCount > 4" class="slide-button" @click="isFiltersExpanded = true">
            <Icon
              name="arrow-right"
              class="slide-icon"
              stroke="var(--color-black)"
              width="20px"
              height="20px"
            />
          </button>
        </div>
        <div v-else class="filters-tags-container transition">
          <div v-for="filter in filters" :key="filter.value" class="filter-component filter-tag">
            {{ filter.text }}
            <Icon
              v-if="!filter.isDisabled"
              name="small-close"
              class="small-close-icon"
              fill="#656464"
              @click="handleDeleteFilter(filter)"
            />
          </div>
          <button class="slide-button" @click="isFiltersExpanded = false">
            <Icon
              name="arrow-left"
              class="slide-icon"
              width="20px"
              height="20px"
              stroke="var(--color-black)"
            />
          </button>
        </div>
      </div>
    </nav>
    <div
      v-if="resultType === 'journey' && !isLoading && results.length > 0"
      ref="resultsGridRef"
      class="results-grid"
      :class="isSeeAll ? 'extended' : 'not-extended'"
    >
      <NuxtLink
        v-for="journey in results.map(journey => journey as JourneyModel)"
        :key="journey.name"
        :to="journey.path"
      >
        <ProductCard :product="journey" />
      </NuxtLink>
    </div>
    <div
      v-else-if="resultType === 'article' && !isLoading && results.length > 0"
      ref="resultsGridRef"
      class="results-grid"
    >
      <EditoCard
        v-for="article in results as ArticleModel[]"
        :key="article.slug"
        :slug="article.slug"
        :title="article.title"
        :subtitle="article.subtitle"
        :description="article.description ?? ''"
        :image="article.imageUrl"
        :link="article.link ?? ''"
        :geographic-zone-slug="article.continent"
        :type="'article'"
      />
    </div>
    <div
      v-else-if="resultType === 'event' && !isLoading && results.length > 0"
      ref="resultsGridRef"
      class="results-grid"
      :class="isSeeAll ? 'extended' : 'not-extended'"
    >
      <EditoCard
        v-for="event in results as EventCard[]"
        :key="event.link"
        :slug="event.slug"
        :title="event.title"
        :subtitle="event.type"
        :description="event.content ?? ''"
        :image="event.imageUrl"
        :link="event.link ?? '/'"
        :geographic-zone-slug="event.geographicZoneSlug"
        :country-slugs="event.countrySlug ? [event.countrySlug] : []"
        :date="event.date"
        :hour="event.hour"
        :type="'event'"
        class="result-card-event"
      />
    </div>
    <span v-else-if="results.length === 0 && !isLoading" class="no-results">
      Il n'y pas de destination pour votre recherche. <br />
      Veuillez réessayer avec d'autres critères.
    </span>
    <div v-if="!isEndOfPagination && !isLoading && results.length > 0" class="cta-lower-banner">
      <div class="cta-container">
        <button class="see-more-btn" @click="emit('on-page-change')">
          <span v-if="isLoadingPagination" class="loader" />
          <span v-else
            >{{
              isSeeAll
                ? `Voir moins`
                : resultType === 'journey'
                  ? `Voir plus de voyages ${itemFullName}`
                  : resultType === 'article' && sectionTitle
                    ? 'Voir plus de carnets de voyage'
                    : `Voir plus`
            }}
          </span>
        </button>
      </div>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loader"></div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { FILTERS_ORDERING } from '@/lib/variables';

import { Category } from '@/lib/strapi-types/Category';

import { Filter, FiltersObject } from '@/lib/types/filters';
import { ArticleModel } from '@/lib/types/models/article';
import { EventCard, ResultModelType } from '@/lib/types/models/common';
import { JourneyModel } from '@/lib/types/models/journey';
import { PaginationByOffset } from '@/lib/types/strapi';

const props = withDefaults(
  defineProps<{
    results: ResultModelType[];
    countries?: Category[];
    itemFullName?: string;
    filtersProp: Filter[];
    isLoading: boolean;
    isLoadingPagination: boolean;
    isMapExtended?: boolean;
    pagination?: PaginationByOffset;
    totalCount?: number;
    showDropdown?: boolean;
    resultType?: 'journey' | 'article' | 'event';
    sectionTitle?: string;
  }>(),
  {
    itemFullName: '',
    isMapExtended: false,
    pagination: undefined,
    totalCount: 0,
    resultType: 'journey',
    countries: undefined,
    showDropdown: false,
    sectionTitle: undefined
  }
);

const emit = defineEmits<{
  (event: 'on-delete', filter: Filter): void;
  (event: 'toggle-modal' | 'toggle-show-mobile-map', toggled: boolean): void;
  (event: 'on-reset' | 'on-page-change'): void;
  (event: 'on-ordering', value: string): void;
  (event: 'on-country-change', value: FiltersObject): void;
}>();

const filters = ref([...props.filtersProp]);
const isFiltersExpanded = ref(false);
const isSeeAll = ref(false);
const resultsGridRef = ref<HTMLElement | null>(null);

const currentOrdering = ref({ label: 'Trier par', value: 'default' });

const filtersCount = computed(
  () => filters.value.filter(filter => filter.isDisabled !== true).length
);

const isEndOfPagination = computed(() => {
  if (!props.pagination || !props.totalCount) return false;

  return !props.isLoadingPagination && props.pagination.limit >= props.totalCount;
});

const countriesOptions = computed(() =>
  [{ label: 'Veuillez sélectionner un pays', value: '' }].concat(
    (props.countries ?? [])
      .filter(country => country.attributes.categoryMdvAttribute === 'country')
      .map(country => ({
        label: country.attributes.title,
        value: country.attributes.urlKey
      }))
  )
);

const currentCountryOption = ref(countriesOptions.value[0]);

const currentCountry = computed(
  () =>
    (props.countries ?? []).find(
      country => country.attributes.urlKey === currentCountryOption.value?.value
    ) ?? null
);

watch([currentCountry], () => {
  if (currentCountry.value) {
    const filtersObject: FiltersObject = {
      destinations: [
        {
          identifier: currentCountry.value.attributes.title,
          isDisabled: true,
          text: currentCountry.value.attributes.title,
          value: {
            code: currentCountry.value.attributes.mdvDstMapsShortname ?? '',
            name: currentCountry.value.attributes.title,
            slug: currentCountry.value.attributes.urlKey,
            type: 'country'
          }
        }
      ],
      seasons: [],
      typeCategories: [],
      categories: [],
      budgets: [],
      durations: [],
      stopoverTownCategories: []
    };
    emit('on-country-change', filtersObject);
  }
});

watch([currentOrdering], () => {
  emit('on-ordering', currentOrdering.value.value);
});

watch(
  () => props.filtersProp,
  () => (filters.value = [...props.filtersProp]),
  { immediate: true }
);

function handleDeleteFilter(toDelete: Filter): void {
  const deleteIndex = filters.value.findIndex(filter => filter.value === toDelete.value);
  filters.value.splice(deleteIndex, 1);

  if (!filters.value.find(filter => filter.value === currentOrdering.value.value)) {
    currentOrdering.value = { label: 'Trier par', value: 'default' };
  }
  emit('on-delete', toDelete);
}

const fullEntityName = computed(() => {
  if (
    props.itemFullName.toLocaleLowerCase().split(' ').includes('voyage', 0) ||
    props.itemFullName.toLocaleLowerCase().split(' ').includes('article', 0) ||
    props.itemFullName.toLocaleLowerCase().split(' ').includes('événement', 0)
  ) {
    return props.itemFullName;
  }

  let prefixName: string = '';

  if (props.totalCount) {
    if (props.resultType === 'journey')
      props.totalCount > 1 ? (prefixName = 'voyages') : (prefixName = 'voyage');
    else if (props.resultType === 'article')
      props.totalCount > 1 ? (prefixName = 'articles') : (prefixName = 'article');
    else props.totalCount ? (prefixName = 'événements') : (prefixName = 'événement');

    return prefixName + ' ' + props.itemFullName;
  }
});
</script>

<style scoped lang="scss">
@use '$/button.scss';
@use '$/components/dropdown.scss';
@use '$/misc.scss';
@use '$/colors.scss';
@use '$/animation.scss';
@use '$/typography.scss';
@use '$/breakpoints.scss';
@use '$/spacings.scss';
@use '$/border-radius.scss';

.results-container {
  scroll-margin-top: 104px;

  position: relative;

  flex-direction: column;

  width: 100%;
  padding: 0 spacings.$desktop-horizontal-lg;

  .top-container {
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    padding: 16px 0;

    .filters-container-default {
      justify-content: space-between;
      width: 100%;

      .results-count-container {
        align-items: center;

        .count-title {
          font-size: 24px;
          font-weight: 600;
          white-space: nowrap;
        }
      }

      .filters-buttons-container {
        gap: 6px;
        align-items: center;

        .filter-button {
          @include button.transparent(small);
          @include button.with-icon();
        }

        .filter-button-outlined {
          @include button.outlined(colors.$black, small, $color-hover: none);
          @include button.with-icon();

          white-space: nowrap;
        }

        .dropdown {
          @include dropdown.small(black, $font-weight: 600);
        }
      }
    }
  }

  .results-grid {
    @include misc.scrollbar();

    scroll-behavior: smooth;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 12px;

    padding-bottom: 24px;

    &.extended {
      overflow: hidden scroll;
    }

    &.not-extended {
      overflow-y: scroll;
    }

    .result-card-event {
      :deep(.title) {
        &.event {
          color: colors.$green;
        }
      }

      :deep(.image) {
        height: 200px;
        min-height: 200px;
      }
    }
  }

  .no-results {
    grid-column: 1 / 5;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 200px;
    margin-top: 24px;

    font-size: typography.$desktop-h4;
    color: colors.$grey-700;
    text-align: center;
  }

  .cta-lower-banner {
    z-index: 4;

    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 10px 0;

    .see-more-btn {
      @include button.plain();

      .loader {
        @include misc.loader($size: 20px, $color: white, $border-width: 2px);
      }
    }

    .cta-container {
      gap: 5px;
      align-items: center;
    }
  }

  .loading-container {
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 300px 0;

    .loader {
      @include misc.loader();
    }
  }

  .filters-container-filtered {
    flex-direction: column;
    width: 100%;

    .results-count-container-filtered {
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .buttons-filter {
        gap: 10px;

        :deep(.drop-down-button) {
          padding: 0;
          padding-right: 12px;
        }
      }

      span {
        font-size: 24px;
        font-weight: 600;
      }

      .icon-filter-count {
        padding: 0;

        .count-bubble {
          z-index: 10;
        }
      }

      .back-button {
        @include button.outlined(colors.$black, small, $color-hover: none);
        @include button.with-icon();

        align-self: center;
        margin-right: 24px;
        padding: 12px;
      }

      .filter-button {
        @include button.transparent(small);
        @include button.with-icon();

        white-space: nowrap;
      }

      .filter-button-outlined {
        @include button.outlined(colors.$black, small, $color-hover: none);
        @include button.with-icon();

        white-space: nowrap;
      }
    }

    .filters-tags-container {
      flex-flow: wrap;
      gap: 5px;
      align-items: center;

      height: fit-content;
      margin-top: 20px;
      padding: 0;

      .slide-button {
        @include button.transparent(small);
        @include button.with-icon();

        padding: 0;
      }

      .slide-icon {
        margin: 8px;
      }

      .filter-tag {
        cursor: pointer;

        gap: 4px;
        align-items: center;

        padding: 4px 8px;

        color: colors.$grey-900;

        border: solid 1px colors.$grey-900;
        border-radius: 4px;

        &.disabled {
          cursor: default;
          opacity: 0.5;
        }
      }
    }

    .transition {
      transition: all 0.8s animation.$easing-ease-out-expo;
    }
  }

  .dropdown-container {
    gap: 24px;
    align-items: center;

    width: 100%;
    max-width: 1300px;
    margin-left: 4rem;

    .dropdown {
      @include dropdown.default($border-color: colors.$grey-900);

      :deep(.drop-down-button) {
        justify-content: space-between;
        min-width: 200px;
        padding: 8px;
        font-size: 16px;
      }
    }
  }
}

.results-container-extended {
  width: 50%;
}

@media screen and (width <= 1024px) {
  .results-container {
    .cta-lower-banner {
      height: 100px;
    }

    .top-container {
      .filters-container-filtered {
        .filters-tags-container {
          margin: 12px 0;
        }
      }
    }
  }
}

@include breakpoints.mobile() {
  .results-container {
    width: 100%;
    padding: 14px 0;

    .dropdown-container {
      gap: 12px;
      width: auto;
      margin-left: 0;

      .dropdown {
        :deep(.drop-down-button) {
          padding: 8px;
          font-size: 16px;
          border-radius: border-radius.$small;
        }
      }
    }

    .results-grid {
      grid-template-columns: 1fr;
      margin: 0;
      padding: 0 16px;

      .result-card-event {
        :deep(.edito-card) {
          max-width: 100%;
        }
      }
    }

    .cta-lower-banner {
      padding: 24px 0;
    }

    &.hide {
      display: none;
    }

    .top-container {
      padding: 0 16px;

      .filters-container-default {
        flex-direction: column;
        width: 100%;

        .results-count-container {
          justify-content: space-between;
          width: 100%;
          margin-bottom: 24px;

          .count-title {
            font-size: 18px;
          }

          span {
            margin-left: 0;
            font-size: 16px;
          }
        }

        .filters-buttons-container {
          gap: 16px;
          justify-content: space-between;
          width: 100%;
          padding-bottom: 24px;

          .filter-button {
            @include button.transparent(very-small);
          }

          :deep(.drop-down-button) {
            width: 100%;
            padding: 0;
          }
        }
      }

      .filters-container-filtered {
        .dropdown {
          @include dropdown.small(black, $font-weight: 600);
        }

        .results-count-container-filtered {
          flex-direction: column-reverse;
          gap: 12px;
          width: 100%;

          .back-button {
            display: none;
          }

          .title-container {
            justify-content: space-between;
            width: 100%;

            .main-title {
              align-self: center;
              font-size: 15px;
            }
          }

          .buttons-filter {
            justify-content: space-between;
            width: 100%;

            :deep(.drop-down-button) {
              width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (width <= 950px) {
  .results-container {
    .top-container {
      .filters-container-filtered {
        .results-count-container-filtered {
          flex-direction: column;
          gap: 16px;
          align-items: flex-start;

          .title-container {
            .main-title {
              align-self: center;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>
